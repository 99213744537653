<template>
  <div class="about">
    <div class="hero is-info">
      <div class="container">
        <h1 class="is-size-3">{{ $t("credential.title") }}</h1>
      </div>
    </div>
    <hr>
    <div class="columns is-multiline">
      <div class="column is-1"></div>
      <div class="column is-10">
        <div class="is-multiline">

          <div class="event-card">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">{{ $t("credential.header") }}</p>
                <input class="input is-info" type="text" v-bind:placeholder="$t('credential.ph-search')" v-model="filterQuery">
                <button class="button is-info" @click="addAccount" v-tooltip="$t('credential.add')">
                  <font-awesome-icon icon="plus-square" />
                </button>
              </header>
              <div class="card-content">
                <div class="content">
                  <table class="table is-striped">
                    <thead>
                    <tr>
                      <th v-tooltip="$t('credential.h-account')">{{ $t("credential.account") }}</th>
                      <th v-tooltip="$t('credential.h-access')">{{ $t("credential.access") }}</th>
                      <th>{{ $t("credential.login") }}</th>
                      <th>{{ $t("credential.password") }}</th>
                      <th v-tooltip="$t('credential.h-operation')">{{ $t("credential.operation") }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(cred) in filtered">
                      <td style="width:20%;"><a @click="toManager(cred.accountName)">{{ cred.accountName }}</a></td>
                      <td style="width:25%;">{{ cred.credentialName }}</td>
                      <td style="width:25%;">{{ cred.loginId }}</td>
                      <td style="width:20%;">
                        <button class="button is-size-7 has-fixed-size" @click="toggleShow(cred)">{{ cred.text }}</button>
                        <span v-if="cred.show">{{ cred.showing }}</span>
                      </td>
                      <td style="width:25%;">
                        <!--button class="button is-small" @click="copyPassword(cred.password)">Copy Password</button-->
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CredentialServiceClient} from "../pb/credential_service_grpc_web_pb";
import {GetCredentialRequest} from "../pb/credential_service_pb";
import router from "@/router";
import security from "@/utility/security";
import {UserClient} from "@/pb/user_service_grpc_web_pb";
import {GetUserRequest} from "@/pb/user_service_pb";
import common from "@/utility/common"

export default {
  name: "Credential",
  data() {
    return {
      user: {},
      accounts: [],
      filterQuery: "",
      showManager: false,
      credList: []
    }
  },
  computed:{
    filtered() {
      if (this.filterQuery == "") {
        return this.credList
      } else {
        return this.credList.filter((item) => {
          return this.filterQuery.toLowerCase().split(' ').every(v => item.accountName.toLowerCase().includes(v))
        })
      }
    }
  },
  methods: {
    async copyPassword(password) {
      let copied = security.decrypt(password, this.getKey())
      console.log(copied)
      await navigator.clipboard.writeText("copy")
    },
    addAccount: function() {
      this.$store.commit('setAccount', null)
      router.push('credential/manage')
    },
    toManager: function(accountName) {
      for (let i in this.accounts) {
        if (this.accounts[i].accountName == accountName) {
          this.$store.commit('setAccount', this.accounts[i])
        }
      }
      router.push("credential/manage/" + accountName)
    },
    toggleShow: function(cred) {
      cred.show = !cred.show
      if (cred.show) {
        cred.text = " hide "
        cred.showing = security.decrypt(cred.password, this.getKey())
      } else {
        cred.text = " show "
        cred.showing = ""
      }
    },
    getKey: function() {
      return this.$store.getters.getUser.password.substr(0, 32)
    },
    getAccounts: function(token, email) {
      let metadata = {'authorization': token}
      this.client = new CredentialServiceClient(common.SECURE_HOST, null, {
        "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
        "grpc.max_send_message_length": common.MESSAGE_LENGTH
      })
      let getCredentialRequest = new GetCredentialRequest()
      getCredentialRequest.setEmail(email)
      this.client.getCredential(getCredentialRequest, metadata, (err, response)=>{
        if (err != null) {
          alert("Failed to get credential: " + err)
          return
        }
        if (response != null) {
          this.accounts = response.toObject().accountsList
          for (let i in this.accounts) {
            for (let j in this.accounts[i].credentialsList) {
              this.accounts[i].credentialsList[j].text = "show"
              this.accounts[i].credentialsList[j].showing = ""
              let cred = {}
              cred.accountName = this.accounts[i].accountName
              cred.credentialName = this.accounts[i].credentialsList[j].credentialName
              cred.loginId = this.accounts[i].credentialsList[j].loginId
              cred.text = "show"
              cred.password = this.accounts[i].credentialsList[j].password
              cred.showing = ""
              this.credList.push(cred)
            }
          }
        }
      })
    }
  },
  mounted() {
    let uid = this.$cookies.get("$uid")
    let token = this.$cookies.get("$t")
    if (this.$store.getters.isLogIn) {
      this.user = this.$store.getters.getUser
      if (this.user.email == "" || this.user.email == undefined || this.user.email == null) {
        let userEmail = this.CryptoJS.AES.decrypt(
            uid, "jumbosecret").toString(this.CryptoJS.enc.Utf8);

        let metadata = {'authorization': token}
        this.client = new UserClient(common.SECURE_HOST, null, {
          "grpc.max_receive_message_length": common.MESSAGE_LENGTH,
          "grpc.max_send_message_length": common.MESSAGE_LENGTH
        })
        let getUserRequest = new GetUserRequest()
        getUserRequest.setEmail(userEmail)
        this.client.getUser(getUserRequest, metadata, (err, response)=>{
          if (err != null) {
            alert("Failed to get user: " + err)
            return
          }
          if (response != null) {
            this.user = response.toObject().usersList[0]
            this.$store.commit('setLoggedInUser', this.user)
            this.$root.$emit('updateAuth', true)
            this.getAccounts(token, this.user.email)
          }
        })
      } else {
        this.getAccounts(token, this.user.email)
      }
    }
  }
}
</script>